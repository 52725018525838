@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root{
    --bgcolor: #1a1a1b;
    --bgcolorvar:#0d0d0e;
    --bgcolorprim: rgb(7, 177, 177);
    --bgcolorprimvar:rgba(77,181, 255, 0.4);
    --bgcolorwhite:#fff;
    --bgcolorlight:rgba(255, 255, 255, 0.6);


    --containerWidthLg: 75%;
    --containerWidthMd: 86%;
    --containerWidthSm: 90%;
    --trans400: all 400 ms ease;
}

.pink{
    color:  rgba(220, 112, 204);

}
.orange{
    color: rgb(241, 127, 70)
}
.purple{
    color: rgba(154, 96, 255);

}
.ltpurple{
    color: rgba(199, 169, 251);
}
.blue{
    color: rgba(66, 173, 240);
}
.green{
    color: rgba(68, 243, 132);
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Quicksand', sans-serif;
    /* background: var(--bgcolor); */
    color: var(--bgcolorwhite);
    line-height: 1.7;
    /* background-image: url(../src/assets/bg-texture.png); */
    height: 100%;
}


.container {
    width: var(--containerWidthLg);
    margin: 0 auto;

}

h1, 
h2, 
h3, 
h4, 
h5{
    font-weight: 500;
}

h1{
    font-size: 2.5rem;
}

section {
    margin-top: 8rem;
    text-align: center;
}

section > h2, section > h5 {
    text-align: center;
}

section > h2{
    margin-bottom: 3rem;
}

/* creates a class */
.text-light{
    color: var(--bgcolorlight);
}

a {
    color: var(--bgcolorprim);
    transition: var(--trans400);
}

a:hover {
    color: var(--bgcolorwhite);
}

.btn{
    width: max-content;
    display: inline-block;
    color: var(--bgcolorprim);
    padding: 0.75rem 1.25rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--bgcolorprim)
}

.btn:hover {
    background-color: var(--bgcolorwhite);
    color: var(--bgcolor);
    border-color: transparent;

}

.btn-prim {
    background: var(--bgcolorprim);
    color: var(--bgcolor)
}

.margin-1-l{
    margin-left: 1rem;
}

.margin-1-r{
    margin-right: 1rem;
}

.margin-h-r{
    margin-right: .5rem;
}
.margin-1-b{
    margin-bottom: 1rem;
}
.margin-2-l{
    margin-left: 2rem;
}
.margin-3-l{
    margin-left: 3rem;
}
.margin-1-t{
    margin-top: 1rem;
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

.page-title{
    margin-top: 0rem;
    margin-bottom: 0rem;
    font-weight: 1000;
    font-size: 40px;
    align-self: center;
    color: black;
}

.Team-t{
    margin-left: 8rem;
    margin-right: 8rem;
    max-width: 1080px;

} 

/* media queries for medium/small devices */

@media screen and (max-width: 1024px){
    container{
        width: var(--containerWidthMd)
    }
    
    section{
        margin-top: 6rem;
    }
}

@media screen and (max-width: 600px){
    container{
        width: var(--containerWidthSm)
    }
    
    section>h2{
        margin-top: 2rem;
    }
    .Team-t{
        margin-top: 0;
        padding-bottom: 0;
        margin-bottom: 0;
        font-size: 35px;
    }

    .Team-t{
        margin-left: 2rem;
        margin-right: 2rem;
    
    } 
}
